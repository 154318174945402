import React, { ChangeEvent, ReactNode } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  FormHelperText,
  FormControlProps,
} from '@material-ui/core';
import { useField } from 'formik';

export type SelectOption = {
  label: string;
  value: string | number;
};

export type SelectProps = {
  name: string;
  label?: string;
  value?: string | number;
  onChange?: (
    event: ChangeEvent<{ name?: string; value: unknown }>,
    child?: ReactNode
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  options: SelectOption[];
  inputRef?: React.Ref<any>;
};

const makeIds = (name: string) => ({
  id: `${name}-select`,
  labelId: `${name}-select-label`,
});

const Select: React.FC<SelectProps> = ({
  name,
  label,
  options,
  ...selectProps
}) => {
  const { id, labelId } = makeIds(name);
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect labelId={labelId} id={id} label={label} {...selectProps}>
        {options.map(({ label, value }) => (
          <MenuItem key={`${label}-menu-item`} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

type FormikSelectProps = FormControlProps & {
  name: string;
  label: string;
  options: SelectOption[];
};

export const FormikSelect: React.FC<FormikSelectProps> = ({
  name,
  label,
  options,
  required=false,
  ...formControlProps
}) => {
  const [field, meta] = useField(name);
  const { id, labelId } = makeIds(name);
  const hasError = !!meta.touched && !!meta.error;
  return (
    <FormControl
      required={required}
      fullWidth
      variant="outlined"
      error={hasError}
      {...formControlProps}
    >
      <InputLabel id={labelId} htmlFor={id}>
        {label}
      </InputLabel>
      <MuiSelect labelId={labelId} id={id} label={label} {...field}>
        {options.map(({ label, value }) => (
          <MenuItem key={`${label}-menu-item`} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
      {hasError && (
        <FormHelperText error style={{ margin: '0px 14px' }}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const CustomSelect: React.FC<SelectProps> = ({
  name,
  label,
  options,
  ...selectProps
}) => {
  const [field, meta, helpers] = useField(name);
  const { id, labelId } = makeIds(name);
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    selectProps.onChange(event, null);
    // Use Formik's setValue to ensure proper state update
    helpers.setValue(selectedValue);
  };
  return (
    <FormControl required={true}
    fullWidth
    variant="outlined">
      <InputLabel id={labelId} htmlFor={id}>{label} </InputLabel>
      <MuiSelect inputRef={selectProps.inputRef}  id={id} label={label} {...field}
       onChange={(e) => handleChange(e)}>
        {options.map(({ label, value }) => (
          <MenuItem key={`${label}-menu-item`} value={label}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
