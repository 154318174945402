import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react';
import Textbox from '../../inputs/textbox/Textbox';
import { buildInputName } from '../../utils/Utils';
import Spacer from '../spacer/Spacer';
import { Country, State, City }  from 'country-state-city';
import {CustomSelect as Select } from '../../inputs/select/Select';

export type CityStateCountryProps = {
  formGroup: string;
};


export type OnChangeHandler = (
  event: ChangeEvent<{ name?: string; value: unknown }>,
) => void;

const CityStateCountry: React.FC<CityStateCountryProps> = ({ formGroup }) => {
  const inputName = buildInputName(formGroup);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const countryRef = useRef(null);

  useEffect( () => {
    if(countriesList.length === 0){
    setCountriesList(Country.getAllCountries().map(country => ({label: country.name, value: country.isoCode})));
    }
    if(countryRef.current.value && countriesList.length > 0){
      const selectedCountry = countriesList?.find(c=> c.label === countryRef.current.value).value;
       setStateList(State.getStatesOfCountry(selectedCountry).map(r=> ({label: r.name, value: r.isoCode})))
    }
  },[countriesList]);

  const handleCountryChange: OnChangeHandler = async (eve) => {
    const selected  = countriesList.find(c=> c.label === eve.target.value).value;
    setSelectedCountry(selected);

    setStateList(State.getStatesOfCountry(selected).map(r=> ({label: r.name, value: r.isoCode})))
  }

  const handleStateChange: OnChangeHandler = async (eve) => {
    const selected  = stateList.find(c=> c.label === eve.target.value).value;
    setSelectedState(selected);
  }

  return (
    <Spacer items={3}>
      <Select name={inputName('country')} label='Country' inputRef={countryRef} options={countriesList} onChange={handleCountryChange}/>
      <Select name={inputName('state')} label='State'  options={stateList} onChange={handleStateChange}/>
      <Textbox required={true} name={inputName('city')} label="City" />
    </Spacer>
  );
};

export default CityStateCountry;
